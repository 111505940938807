import { createGlobalState, useStorage } from '@vueuse/core';
import { reactive, ref } from 'vue';
import { StateType, Toast } from './types';

export const useState = createGlobalState(() =>
    reactive<StateType>({
        eth_config: import.meta.env.VITE_ETH_CONFIG ? JSON.parse(import.meta.env.VITE_ETH_CONFIG) : undefined,
        wax_config: import.meta.env.VITE_WAX_CONFIG ? JSON.parse(import.meta.env.VITE_WAX_CONFIG) : undefined,
        bridge_url: import.meta.env.VITE_BRIDGE_URL ? import.meta.env.VITE_BRIDGE_URL : undefined,
        owned_url: import.meta.env.VITE_EVM_NFTS_URL ? import.meta.env.VITE_EVM_NFTS_URL : undefined,
        allowed_bridge_in: import.meta.env.VITE_ALLOWED_BRIDGE_IN_CHAINS
            ? JSON.parse(import.meta.env.VITE_ALLOWED_BRIDGE_IN_CHAINS)
            : [],
        ethAccount: null,
        waxAccount: null,
        asset: null,
        bridgable: null,
        chain_config: null,
    })
);

export const transfers = useStorage<Record<string, string>>('transferred_assets_bridge_nefty', {});

export const waxAuth = ref(false);
export const ethAuth = ref(false);

export const pageLoading = ref(false);
export const toasts = ref<Toast[]>([]);
