<template>
    <header class="header">
        <div class="header-content">
            <RouterLink v-if="back" class="header-link back" :to="back.path">
                <svg role="presentation" focusable="false" aria-hidden="true">
                    <use xlink:href="#arrow_left_long" />
                </svg>
                {{ back.name }}
            </RouterLink>

            <article v-if="app">
                <div class="wax-user">
                    <WaxUser v-if="waxAuth && store.waxAccount" />
                </div>

                <div class="slot">
                    <slot></slot>
                </div>

                <div class="eth-user">
                    <EthUser v-if="ethAuth && store.ethAccount" />
                </div>
            </article>

            <RouterLink v-if="bridge" class="header-link bridge" to="/bridge">
                Bridge
                <svg role="presentation" focusable="false" aria-hidden="true">
                    <use xlink:href="#arrow_right_long" />
                </svg>
            </RouterLink>
        </div>
    </header>
</template>

<script setup lang="ts">
import EthUser from '@core/EthUser.vue';
import WaxUser from '@core/WaxUser.vue';
import { useState, waxAuth, ethAuth } from '../../store';

defineProps({
    bridge: {
        type: Boolean,
        default: false,
    },
    back: {
        type: Object,
        default: null,
    },
    app: {
        type: Boolean,
        default: true,
    },
});

const store = useState();
</script>

<style lang="scss">
.header {
    width: 100%;
    z-index: 1;
    padding: $spacing;
}

.header-content {
    @include max-width;

    display: grid;

    article {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: $spacing;
        text-align: center;
        z-index: 1;
        transform: translateY(2.5rem);

        h1 {
            text-transform: capitalize;
        }

        p {
            max-width: 30rem;
            margin: 0 auto;
            color: var(--color-alt);
        }
    }

    .wax-user,
    .eth-user {
        min-width: 14rem;
        width: fit-content;
    }

    .slot {
        order: 0;
        margin: 0 auto;
        width: 100%;
    }

    .wax-user {
        order: 1;
    }

    .eth-user {
        order: 2;
    }
}

a.header-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    color: var(--color);
    text-decoration: none;

    svg {
        width: 8rem;

        height: 2rem;
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        border-bottom: none;
        color: var(--color);

        svg {
            transform: scaleX(1.1);
        }
    }
}

.bridge {
    margin-left: auto;
    grid-column: 3;

    svg {
        margin-left: 2rem;
    }
}

.back {
    margin-right: auto;
    grid-column: 1;

    svg {
        margin-right: 2rem;
    }
}

@media all and (min-width: $m) {
    .header {
        padding: 0 $spacing;
    }

    .header-content {
        min-height: 12rem;
        grid-template-columns: 1fr 2fr 1fr;

        article {
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: nowrap;
            text-align: center;
        }

        .wax-user,
        .eth-user {
            padding-top: $spacing * 0.8;
        }

        .slot {
            order: 1;
            width: fit-content;
        }

        .wax-user {
            order: 0;
        }

        .eth-user {
            order: 2;
        }
    }
}
</style>
