import { PushTransactionArgs } from 'eosjs/dist/eosjs-rpc-interfaces';
import { SignatureProvider, SignatureProviderArgs } from 'eosjs/dist/eosjs-api-interfaces';
import {
    SignatureResult,
    TransactionAction,
    TransactionBridgeParams,
    TransactionClaimParams,
    TransactionFinal,
} from '../types';
import { deBuffer } from './transformers';
import { useState } from '../store';
import { WombatUser } from 'wombat-ual';
import { WaxUser } from '@nefty/ual-wax';

const store = useState();

export const waxSignatureProvider: SignatureProvider = {
    async getAvailableKeys(): Promise<string[]> {
        const { wax_config } = store;

        return Promise.resolve([wax_config.cpu.key]);
    },
    async sign(args: SignatureProviderArgs): Promise<PushTransactionArgs> {
        const { wax_config } = store;

        const { requiredKeys, serializedTransaction, serializedContextFreeData } = args;

        if (!requiredKeys.includes(wax_config.cpu.key)) {
            return {
                signatures: [],
                serializedTransaction,
                serializedContextFreeData,
            };
        }

        try {
            const signingResult = await fetch(wax_config.cpu.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    transaction: {
                        signatures: [],
                        serializedTransaction: deBuffer(serializedTransaction),
                        serializedContextFreeData: serializedContextFreeData
                            ? deBuffer(serializedTransaction)
                            : serializedContextFreeData,
                    },
                    paidCpu: true,
                    signOnly: true,
                }),
            });
            const signingResultJson: SignatureResult = await signingResult.json();

            if (!signingResultJson?.signatures) {
                throw new Error('Invalid result returned by CPU payer');
            }
            return {
                signatures: signingResultJson.signatures,
                serializedTransaction,
                serializedContextFreeData,
            };
        } catch (error) {
            throw new Error('Failed to fetch signature from API signer');
        }
    },
};

export const transactionBridgeActions = ({
    user,
    asset_id,
    adress,
    chain,
    fee,
    collection_name,
}: TransactionBridgeParams): TransactionAction[] => {
    const { accountName, requestPermission } = user;

    if (!accountName || !asset_id || !adress || !chain || !collection_name) {
        throw new Error('Missing required params');
    }

    const transactions = [
        {
            account: 'bridge.nefty',
            name: 'openbridge',
            authorization: [
                {
                    actor: accountName,
                    permission: requestPermission,
                },
            ],
            data: {
                authorized_account: accountName,
                asset_id: asset_id,
            },
        },
        {
            account: 'atomicassets',
            name: 'transfer',
            authorization: [
                {
                    actor: getNameForTransaction(accountName),
                    permission: requestPermission,
                },
            ],
            data: {
                from: getNameForTransaction(accountName),
                to: 'bridge.nefty',
                asset_ids: [asset_id],
                memo: `bridge:${getEthNameForTransaction(adress)}:${chain}`,
            },
        },
    ];

    if (fee) {
        // insert fee transaction after openbridge
        transactions.splice(1, 0, {
            account: fee!.contract,
            name: 'transfer',
            authorization: [
                {
                    actor: getNameForTransaction(accountName),
                    permission: requestPermission,
                },
            ],
            data: {
                from: getNameForTransaction(accountName),
                to: 'bridge.nefty',
                quantity: fee!.quantity,
                memo: `pay:${collection_name}:${asset_id}`,
            },
        } as any);
    }

    return transactions;
};

export const transactionClaimActions = ({
    user,
    asset_id,
    collection_name,
}: TransactionClaimParams): TransactionAction[] => {
    const { accountName, requestPermission } = user;

    if (!accountName || !asset_id || !collection_name) {
        throw new Error('Missing required params');
    }
    return [
        {
            account: 'bridge.nefty',
            name: 'claim',
            authorization: [
                {
                    actor: accountName,
                    permission: requestPermission,
                },
            ],
            data: {
                collection_name: collection_name,
                asset_id: asset_id,
            },
        },
    ];
};

export const executeTransaction = async (actions: TransactionAction[]) => {
    try {
        const { wax_config } = store;
        const user = window.provider_user;

        const payCpu = !(user instanceof WombatUser);
        const paidCpu = !(user instanceof WaxUser) && !(user instanceof WombatUser);

        const { transaction, options } = createTransaction(actions, payCpu);
        const { transaction: completedTransaction } = await user!.signTransaction(transaction, options);
        const { signatures, compression, serializedTransaction, serializedContextFreeData } = completedTransaction;

        const res = await fetch(wax_config.cpu.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                transaction: {
                    signatures,
                    compression,
                    serializedTransaction: deBuffer(serializedTransaction),
                    serializedContextFreeData: serializedContextFreeData
                        ? deBuffer(serializedTransaction)
                        : serializedContextFreeData,
                },
                paidCpu,
                options,
            }),
        });

        const result: TransactionFinal = await res.json();

        if (result.error) throw new Error(result.error.message);

        const { processed, transaction_id } = result;

        return { processed, transaction_id };
    } catch (error: any) {
        return { error: error.message };
    }
};

export const createTransaction = (transactionActions: TransactionAction[], payCpu = true) => {
    const { wax_config } = store;

    const paycpuActions = {
        account: wax_config.cpu.account,
        name: 'paycpu',
        authorization: [
            {
                actor: wax_config.cpu.account,
                permission: wax_config.cpu.permission,
            },
        ],
        data: {},
    };

    return {
        transaction: {
            actions: [...(payCpu ? [paycpuActions] : []), ...transactionActions],
        },
        options: {
            blocksBehind: 3,
            expireSeconds: 120,
            sign: true,
            broadcast: false,
        },
    };
};

const getNameForTransaction = (name: string): string => name.toLowerCase().trim();

export const getEthNameForTransaction = (name: string): string => {
    const nameWithout0x = name.replace('0x', '');
    // make sure the name is 64 characters long
    return nameWithout0x.toLowerCase().trim().padEnd(64, '0');
};
