import { useState } from '../store';
import { BridgableCollectionResult, BridgableCollection, AnnouncementService } from '../types';
import { useFetch } from '@mvdschee/use';

const store = useState();
const { bridge_url, wax_config } = store;

export const getBridgableCollections = async (): Promise<BridgableCollectionResult> => {
    const result: BridgableCollectionResult = {
        search: [],
        collections: {},
        collections_reverse: {},
    };

    const { data, error } = await useFetch<any>('/v1/chain/get_table_rows', {
        baseUrl: wax_config.rpc_url,
        method: 'POST',
        body: {
            json: true,
            code: 'bridge.nefty',
            scope: 'bridge.nefty',
            table: 'collections',
            lower_bound: null,
            upper_bound: null,
            index_position: 1,
            key_type: '',
            limit: '-1',
            reverse: false,
            show_payer: true,
        },
    });

    if (error) console.error(error);
    if (data) {
        const { rows } = data;

        for (let i = 0; i < rows.length; i++) {
            const { data } = rows[i];

            const contracts = data.contracts.map((contract: BridgableCollection['contracts']) => {
                return contract.key;
            });

            const contractsObj = Object.fromEntries(
                data.contracts.map(({ key, value }: BridgableCollection['contracts']) => [key, value])
            );

            result.search.push(data.collection_name);

            result.collections[data.collection_name] = {
                name: data.collection_name,
                supported_contracts: contracts,
                allowed_schemas: data.allowed_schemas,
                pay_fee: !!data.is_paid,
                mint_one_only: !!data.mint_one_only,
                contracts: contractsObj,
            };

            for (let i = 0; i < data.contracts.length; i++) {
                const contract = data.contracts[i];
                result.collections_reverse[contract.value] = {
                    name: data.collection_name,
                    chain_id: contract.key,
                };
            }
        }
    }

    return result;
};

export const getParamsGen = async ({
    asset_id,
    collection,
}: {
    asset_id: string;
    collection: string;
}): Promise<any> => {
    // error is wrapped in data
    const { data } = await useFetch<any>('/api/v1/generate-bridge-params', {
        baseUrl: bridge_url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            assetId: asset_id,
            collection: collection,
        },
    });

    return data;
};

export const getParamsEVM = async ({
    asset_id,
    collection,
}: {
    asset_id: string;
    collection: string;
}): Promise<any> => {
    // error is wrapped in data
    const { data } = await useFetch<any>('/api/v1/bridge-to-eth', {
        baseUrl: bridge_url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            assetId: asset_id,
            collection: collection,
        },
    });

    return data;
};

export const getParamsWAX = async ({
    asset_id,
    collection,
}: {
    asset_id: string;
    collection: string;
}): Promise<any> => {
    // error is wrapped in data
    const { data } = await useFetch<any>('/api/v1/bridge-to-wax', {
        baseUrl: bridge_url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            assetId: asset_id,
            collection: collection,
        },
    });

    return data;
};

export const loadAnnouncements = async () => {
    const now = Math.floor(new Date().valueOf() / 1000);

    let response: AnnouncementService[] = [];

    const { data, error } = await useFetch<any>('/v1/chain/get_table_rows', {
        baseUrl: wax_config.rpc_url,
        method: 'POST',
        body: {
            json: true,
            code: 'banner.nefty',
            scope: 'bridge',
            table: 'announcement',
            lower_bound: null,
            upper_bound: null,
            index_position: 1,
            key_type: '',
            limit: '100',
            reverse: false,
            show_payer: false,
        },
    });

    if (error) console.error(error);

    if (data) {
        const { rows } = data;

        if (rows?.length) {
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];

                if ((row.start_time === 0 || row.start_time < now) && (row.end_time === 0 || row.end_time >= now)) {
                    response.push({
                        announcement_id: row.announcement_id,
                        title: row.title,
                        text: row.text,
                        url: row.url,
                        new_tab: row.open_in_new_tab,
                    });
                }
            }
        }
    }

    return response;
};
