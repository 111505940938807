<template>
    <div ref="target" class="menu menu--user">
        <button id="account-eth" class="menu-btn user-btn" @click="openUserMenu">
            <UserIcon :account="store.ethAccount!.wallet" />
            <p>
                {{ displayWallet }}
                <small>({{ store.ethAccount!.network }})</small>
            </p>
        </button>

        <Transition name="fade-down">
            <div v-if="active" class="menu-list menu-list--user">
                <div class="user-profile" @click="copy(store.ethAccount!.wallet)">
                    <UserIcon :account="store.ethAccount!.wallet" />
                    <p class="wallet">{{ copied ? 'Copied' : displayWallet }}</p>
                </div>
                <div class="balance">
                    <legend>Balance</legend>
                    <p class="token">
                        {{ tokenDisplay(+store.ethAccount!.balance, 8) }} <span>{{ store.ethAccount!.symbol }}</span>
                    </p>
                </div>
                <button id="account-eth-logout" class="menu-item logout" @click="logout">
                    <svg role="presentation" focusable="false" aria-hidden="true">
                        <use xlink:href="#sign_out" />
                    </svg>
                    Sign out
                </button>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useClipboard, onClickOutside } from '@vueuse/core';
import { tokenDisplay } from '../../utils';
import { useState, ethAuth } from '../../store';
import { computed, watch, ref } from 'vue';

import UserIcon from '@elements/UserIcon.vue';

const { copy, copied } = useClipboard();

const store = useState();

// LOCAL
const target = ref();
const active = ref(false);
const name = ref<string | null>(null);

const openUserMenu = async () => {
    active.value = !active.value;

    store.ethAccount!.balance = await window.rpc.getBalance(store.ethAccount!.wallet);
};

onClickOutside(target, () => (active.value = false));

const displayWallet = computed(() => {
    const lenght = store.ethAccount!.wallet.length;

    return (
        name.value ||
        `${store.ethAccount!.wallet.substring(0, 5)}...${store.ethAccount?.wallet.substring(lenght - 4, lenght)}`
    );
});

watch(store, async () => {
    try {
        name.value = await window.rpc.publicProvider!.lookupAddress(store.ethAccount!.wallet);
    } catch (e) {
        // do nothing
    }
});

const logout = async () => {
    await window.web3Modal.clearCachedProvider();

    ethAuth.value = false;
    store.ethAccount = null;
};
</script>

<style scoped lang="scss"></style>
