export const tokenDisplay = (value: string | number, precision = 8, enforce = false): string => {
    const number = typeof value === 'number' ? value : parseFloat(value);

    if (Number.isNaN(number)) return '0';

    // TODO: which fraction digits to use?
    return number.toLocaleString('EN', {
        maximumFractionDigits: precision,
        minimumFractionDigits: enforce ? precision : 0,
    });
};

export const displayWallet = (account: string) => {
    const lenght = account.length;

    return `${account.substring(0, 5)}...${account.substring(lenght - 4, lenght)}`;
};

export const wordCap = (string: string, length = 32) => {
    if (!string) return '';
    return string.length <= length ? string : `${string.slice(0, length)}...`;
};
