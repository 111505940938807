<template>
    <div ref="target" class="menu menu--user">
        <button id="account-wax" class="menu-btn user-btn" @click="openUserMenu">
            <UserIcon :account="store.waxAccount!.wallet" :seed="22" />
            <p>
                {{ store.waxAccount!.wallet }}
                <small>({{ store.waxAccount!.network }})</small>
            </p>
        </button>

        <Transition name="fade-down">
            <div v-if="active" class="menu-list menu-list--user">
                <div class="user-profile" @click="copy(store.waxAccount!.wallet)">
                    <UserIcon :account="store.waxAccount!.wallet" :seed="22" />
                    <p class="wallet">{{ copied ? 'Copied' : store.waxAccount!.wallet }}</p>
                </div>
                <div class="balance">
                    <legend>Balance</legend>
                    <p class="token">
                        {{ store.waxAccount!.balance }} <span>{{ store.waxAccount!.symbol }}</span>
                    </p>
                </div>
                <button id="account-wax-logout" class="menu-item logout" @click="logout">
                    <svg role="presentation" focusable="false" aria-hidden="true">
                        <use xlink:href="#sign_out" />
                    </svg>
                    Sign out
                </button>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useClipboard, onClickOutside } from '@vueuse/core';
import { tokenDisplay } from '../../utils';
import { getUserBalance } from '../../services/wax';
import { useState, waxAuth } from '../../store';

import UserIcon from '@elements/UserIcon.vue';

const { copy, copied } = useClipboard();

const store = useState();

// LOCAL
const target = ref();
const active = ref(false);

const openUserMenu = async () => {
    active.value = !active.value;

    try {
        const balance = await getUserBalance(store.waxAccount!.wallet);

        store.waxAccount!.balance = tokenDisplay(balance, 3);
        store.waxAccount!.amount = balance;
    } catch (error) {
        console.log(error);
    }
};

onClickOutside(target, () => (active.value = false));

const logout = async () => {
    await window.provider.logoutUser();
    window.provider_user = null;

    store.waxAccount = null;
    waxAuth.value = false;
};
</script>

<style scoped lang="scss"></style>
