<template>
    <ul class="social">
        <li v-for="social in socials" :key="social.name">
            <a
                class="social-link"
                :href="social.url"
                target="_blank"
                rel="noopener noreferrer"
                :aria-label="`External link to ${social.name}`"
            >
                <svg role="presentation" focusable="false" aria-hidden="true">
                    <use :xlink:href="`#${social.icon}`" />
                </svg>
            </a>
        </li>
    </ul>
</template>

<script setup lang="ts">
const socials = [
    {
        name: 'Twitter',
        url: 'https://twitter.com/neftyblocks?utm_source=bridge&utm_campaign=footer',
        icon: 'twitter',
    },
    {
        name: 'Discord',
        url: 'https://discord.com/invite/Bf789hjAsE?utm_source=bridge&utm_campaign=footer',
        icon: 'discord',
    },
    {
        name: 'Medium',
        url: 'https://neftyblocks.medium.com?utm_source=bridge&utm_campaign=footer',
        icon: 'medium',
    },
    {
        name: 'YouTube',
        url: 'https://youtube.com/channel/UCAOiiR1k9uF6zvvsmfnws3w?utm_source=bridge&utm_campaign=footer',
        icon: 'youtube',
    },
    {
        name: 'Instragram',
        url: 'https://instagram.com/neftyblocks?utm_source=bridge&utm_campaign=footer',
        icon: 'instagram',
    },
];
</script>

<style scoped lang="scss">
.social {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing * 0.8;
    align-items: center;
}

.social-link {
    display: block;
    width: 2.4rem;
    height: 2rem;
    color: var(--color-alt);
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    transition: color 0.2s ease-in-out;

    &:hover {
        border-bottom: none;
    }
}
</style>
