import { getAssetDataSet } from '../utils';
import { useState } from '../store';
import { Asset, BridgableCollection } from '../types';
import { useFetch } from '@mvdschee/use';

const store = useState();
const { wax_config } = store;

const tableURL = `${wax_config.rpc_url}/v1/chain/get_table_rows`;

export const getBridgedAssetState = async ({
    collection,
    asset_id,
}: {
    collection: string;
    asset_id: string;
}): Promise<any> => {
    const result: any = {
        stage: 'destination',
        chain_id: 0,
        uid: null,
        signature: null,
        tx: null,
        owner: 'bridge.nefty',
    };

    try {
        const resAsset = await fetch(tableURL, {
            method: 'POST',
            body: JSON.stringify({
                json: true,
                code: 'bridge.nefty',
                scope: collection,
                table: 'bridged',
                lower_bound: asset_id,
                upper_bound: asset_id,
                index_position: 1,
                key_type: '',
                limit: '1',
                reverse: false,
                show_payer: true,
            }),
        });

        const { rows } = await resAsset.json();

        if (rows.length) {
            const { data } = rows[0];

            if (data.claimable === 1) {
                result.stage = 'transfer';
                result.chain_id = data.chain_id;
                result.owner = data.owner;
            }

            if (data.uid !== '0000000000000000000000000000000000000000000000000000000000000000') {
                result.stage = 'claim';
                result.chain_id = data.chain_id;
                result.uid = data.uid;
                result.signature = data.eth_signature;
                result.tx = data.tx;
                result.owner = data.owner;
            }
        }
    } catch (error) {
        console.error(error);
    }

    return result;
};

export const getIrreversibleBlockNumber = async (): Promise<any> => {
    try {
        const resAsset = await fetch(`${wax_config.rpc_url}/v1/chain/get_info`, {
            method: 'GET',
        });

        const data = await resAsset.json();

        return data.last_irreversible_block_num;
    } catch (error) {
        console.error(error);
    }

    return 0;
};

export const getAssetInformation = async (id: string): Promise<Asset | null> => {
    try {
        const res = await fetch(`${wax_config.api_url}/atomicassets/v1/assets/${id.trim()}`);

        const data = await res.json();

        if (data.success) {
            return createAssetObject(data.data);
        }
    } catch (error) {
        console.error(error);
    }

    return null;
};

export const getAssetsInformation = async (ids: string[]): Promise<Asset[]> => {
    const results = [];

    try {
        const res = await fetch(`${wax_config.api_url}/atomicassets/v1/assets?ids=${ids.join(',')}`, {});

        const dataset = await res.json();

        if (dataset.success) {
            for (let i = 0; i < dataset.data.length; i++) {
                results.push(createAssetObject(dataset.data[i]));
            }
        }
    } catch (error) {
        console.error(error);
    }

    return results;
};

export const getAllUserOwnedAssets = async ({ account }: { account: string }) => {
    const store = useState();
    const { bridgable } = store;

    const data: any[] = [];

    if (bridgable) {
        const keys = Object.keys(bridgable.collections);

        for (let i = 0; i < keys.length; i++) {
            const collection = bridgable.collections[keys[i]];

            const assets = await getUserOwnedAssets({ account, collection });

            if (assets.length) {
                data.push(...assets);
            }
        }
    }

    return data;
};

export const getUserOwnedAssets = async ({
    account,
    collection,
}: {
    account: string;
    collection: BridgableCollection | null;
}): Promise<any> => {
    const store = useState();
    const { wax_config } = store;

    const results = [];

    const mint: any = collection?.mint_one_only
        ? {
              min_template_mint: '1',
              max_template_mint: '1',
          }
        : {};

    const schema: any = collection?.allowed_schemas.length ? { schema_name: collection.allowed_schemas.join(',') } : {};

    const { data: res, error } = await useFetch<any>('/atomicassets/v1/assets', {
        baseUrl: wax_config.api_url,
        params: {
            owner: account,
            collection_name: collection?.name || '',
            is_transferable: 'true',
            sort: 'transferred',
            order: 'desc',
            page: '1',
            ...mint,
            ...schema,
        },
    });

    if (error) console.error(error);

    if (res) {
        for (let i = 0; i < res.data.length; i++) {
            results.push(createAssetObject(res.data[i]));
        }
    }

    return results;
};

export const getUserBalance = async (account: string, code = 'token.nefty', symbol = 'NEFTY') => {
    const store = useState();
    const { wax_config } = store;

    try {
        const res = await fetch(`${wax_config.rpc_url}/v1/chain/get_currency_balance`, {
            method: 'POST',
            body: JSON.stringify({
                code,
                symbol,
                account: account,
            }),
        });

        const data = await res.json();

        if (data.length) {
            const [amount] = data[0].split(' ');

            return +amount;
        }
    } catch (error) {
        console.error(error);
    }

    return 0;
};

export const getBridgeFee = async () => {
    try {
        const resAsset = await fetch(tableURL, {
            method: 'POST',
            body: JSON.stringify({
                json: true,
                code: 'bridge.nefty',
                scope: 'bridge.nefty',
                table: 'config',
                index_position: 1,
                key_type: '',
                limit: '-1',
                reverse: false,
                show_payer: true,
            }),
        });

        const { rows } = await resAsset.json();

        if (rows.length) {
            return rows[0].data.fee;
        }
    } catch (error) {
        console.error(error);
    }

    return {
        quantity: '0.0000 NEFTY',
        contract: 'token.nefty',
    };
};

function createAssetObject(data: any) {
    const { asset_id, collection, template, template_mint, owner, updated_at_block, schema } = data;

    const dataSet = getAssetDataSet(data);

    return {
        id: asset_id,
        owner: owner,
        schema_name: schema.schema_name || null,
        name: dataSet?.name || null,
        image: dataSet?.img ? `https://resizer.neftyblocks.com?ipfs=${dataSet.img}&width=300&static=true` : null,
        collection: collection.collection_name,
        collection_img: collection.img
            ? `https://resizer.neftyblocks.com?ipfs=${collection.img}&width=100&static=true`
            : null,
        mint: template ? template_mint : '1',
        updated_at_block,
    };
}
