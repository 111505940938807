import { createRouter, createWebHistory } from 'vue-router';

// Layouts
import App from './layouts/App.vue';
import Public from './layouts/Public.vue';

// Pages
import Home from './pages/Home.vue';
import { pageLoading } from './store';

const app = document.querySelector('body');

const routes = [
    {
        path: '/',
        component: Public,
        meta: { class: 'home' },
        children: [
            {
                path: '',
                component: Home,
            },
        ],
    },
    {
        path: '/bridge',
        component: App,
        meta: { class: 'app' },
        children: [
            {
                path: '',
                component: () => import('./pages/Overview.vue'),
            },
        ],
    },
    {
        path: '/bridge-out',
        component: App,
        meta: { title: 'NFTs out', class: 'app' },
        children: [
            {
                path: '',
                component: () => import('./pages/AssetsOut.vue'),
            },
        ],
    },
    {
        path: '/bridge-out/:collection/:asset',
        component: App,
        meta: { title: 'Transfer', class: 'app' },
        children: [
            {
                path: '',
                component: () => import('./pages/TransferOut.vue'),
            },
        ],
    },
    {
        path: '/bridge-in',
        component: App,
        meta: { title: 'NFTs in', class: 'app' },
        children: [
            {
                path: '',
                component: () => import('./pages/AssetsIn.vue'),
            },
        ],
    },
    {
        path: '/bridge-in/:collectionContract/:asset',
        component: App,
        meta: { title: 'Transfer', class: 'app' },
        children: [
            {
                path: '',
                component: () => import('./pages/TransferIn.vue'),
            },
        ],
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = `${to.meta.title} | NeftyBlocks Bridge`;
    else document.title = 'NeftyBlocks Bridge';

    if (to.meta.class && app) app.className = `layout layout-animation layout-${to.meta.class}`;

    pageLoading.value = true;

    next();
});

router.afterEach((to, from) => {
    pageLoading.value = false;

    setTimeout(() => {
        if (to.meta.class && app) app.className = `layout layout-${to.meta.class}`;
    }, 800);
});
