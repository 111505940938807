<template>
    <Transition name="fade">
        <div v-if="allowedItems?.length" class="accouncements">
            <svg role="presentation" focusable="false" aria-hidden="true">
                <use xlink:href="#megaphone" />
            </svg>
            <Transition name="fade" mode="out-in">
                <div class="accouncement" v-if="allowedItems[index]" :key="index">
                    <article>
                        <p v-if="allowedItems[index].text || allowedItems[index].title">
                            <strong v-if="allowedItems[index].title">{{ allowedItems[index].title }}</strong>
                            {{ wordCap(allowedItems[index].text || ' ', 120) }}
                        </p>
                        <RouterLink
                            v-if="allowedItems[index].url"
                            :to="allowedItems[index].url || '#'"
                            :target="allowedItems[index].new_tab ? '_blank' : null"
                        >
                            See details
                        </RouterLink>
                        <button class="btn-clear" @click="() => clear(allowedItems[index].announcement_id)">
                            <svg role="presentation" focusable="false" aria-hidden="true">
                                <use xlink:href="#cancel" />
                            </svg>
                        </button>
                    </article>
                </div>
            </Transition>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { AnnouncementService } from '../../types';
import { loadAnnouncements } from '../../services/bridge';
import { wordCap } from '../../utils';

const items = ref<AnnouncementService[] | null>(null);

const localAllowed = ref<number[]>([]);
const allowed = computed({
    get() {
        const allowed = localStorage.getItem('announcements');
        if (allowed) localAllowed.value = JSON.parse(allowed);

        return localAllowed.value;
    },

    set(value: number[]) {
        localStorage.setItem('announcements', JSON.stringify(value));

        localAllowed.value = value;
    },
});

const index = ref(0);
const allowedItems = computed(() => items.value?.filter((item) => !allowed.value.includes(item.announcement_id)) || []);

onBeforeMount(async () => {
    items.value = await loadAnnouncements();
});

onMounted(() => {
    setInterval(() => {
        const next = index.value + 1;
        if (next >= allowedItems.value!.length) {
            index.value = 0;
        } else {
            index.value = next;
        }
    }, 5000);
});

const clear = (id: number) => {
    allowed.value = [...allowed.value, id];
};
</script>

<style lang="scss">
.accouncements {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    gap: 2rem;
    z-index: 1;
    padding: $spacing * 0.3 $spacing * 0.5;
    background-color: var(--primary-3);
    color: var(--black);
    min-height: 8rem;

    svg {
        flex: 0 0 3rem;
        width: $font-xl;
        height: $font-xl;
    }
}

.accouncement {
    display: flex;
    max-width: $content-width * 1.4;
    margin: 0 auto;

    a {
        font-size: $font-s;
        color: var(--black);
        cursor: pointer;
        text-align: center;
        line-height: 1.1;
        grid-area: link;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        text-decoration: underline;

        &:hover {
            border-bottom: none;
            color: var(--black);
        }
    }

    article {
        display: grid;
        grid-template-columns: 1fr 3rem;
        grid-template-rows: 1fr auto;
        grid-template-areas: 'text button' 'link button';
        align-items: center;
        gap: 0 1rem;
    }

    p {
        font-size: $font-s;
        color: var(--black);
        line-height: 1.1;
        grid-area: text;
    }

    strong {
        font-size: $font-s * 1.1;
    }

    button {
        z-index: 1;
        height: 2rem;
        margin-left: auto;
        cursor: pointer;
        grid-area: button;
    }
}

@media all and (min-width: $m) {
    .accouncements {
        padding: $spacing * 0.3 $spacing;
        min-height: 5rem;

        article {
            display: flex;
            align-items: center;
        }

        svg {
            flex: 0 1 3rem;
        }
    }
}
</style>
