<template>
    <router-view v-if="store.bridgable" v-slot="{ Component }">
        <transition name="page" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
    <div v-else class="loading-center">
        <Loader class="center" />
        <p>{{ randomFromMessages() }}</p>
    </div>
</template>
<script setup lang="ts">
import { useInitState } from '../composable';
import { useState } from '../store';

import Loader from '@elements/Loader.vue';

const store = useState();

const loadingMessages = [
    'Initializing the initializer...',
    'Downloading more RAM..',
    'Please wait... Consulting the manual...',
    'Loading the loading screen...',
    'Looking for sense of humour, please hold on.',
    'Dividing by zero...',
    'One mississippi, two mississippi...',
    'You are number 2843684714 in the queue',
];

const randomFromMessages = () => {
    return loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
};

useInitState();
</script>

<style scoped lang="scss">
.loading-center {
    margin: 40vh auto 0;
    text-align: center;
    width: fit-content;
}
</style>
