export const getAssetDataSet = (asset: any): Record<string, string> | null => {
    const { immutable_data } = asset;
    const { data, template } = asset;
    return data
        ? data
        : immutable_data
        ? immutable_data
        : template && template.immutable_data
        ? template.immutable_data
        : null;
};

export const compareAddress = (a: string, b: string): boolean => {
    return a.toLowerCase() === b.toLowerCase();
};

export const addressToWaxContract = (address: string): string => {
    const nameWithout0x = address.replace('0x', '');
    // make sure the name is 64 characters long
    return nameWithout0x.toLowerCase().trim().padEnd(64, '0');
};

export const waxContractToAddress = (contract: string): string => {
    const nameWith0x = `0x${contract}`;
    // remove any trailing 0
    return nameWith0x.replace(/0+$/, '');
};
