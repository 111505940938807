<template>
    <teleport to="body">
        <transition-group v-if="toasts.length" name="fade-left" tag="ul" class="toasts">
            <li v-for="(toast, index) in toasts" :key="index" class="toast" :class="`toast--${toast.type}`">
                <p>
                    {{ toast.message }}
                </p>
                <a
                    v-if="toast.link"
                    :title="`${toast.message}: ${toast.link.text}`"
                    :href="toast.link.href"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ toast.link.text }}</a
                >
            </li>
        </transition-group>
    </teleport>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { toasts } from '../../store';

onMounted(() => {
    setInterval(() => {
        toasts.value = toasts.value.filter((message) => message.timestamp! > Date.now() - 5000);
    }, 4500);
});
</script>

<style lang="scss">
.toasts {
    position: fixed;
    top: $spacing * 2;
    right: $spacing;
    display: flex;
    flex-direction: column;
    gap: $spacing;
    z-index: 8;
}
.toast {
    color: var(--color);
    padding: $spacing * 0.5;
    border-radius: $radius * 0.5;
    width: 20rem;
    max-height: 12rem;
    overflow: hidden;
    backdrop-filter: blur(10px);

    &--info {
        background-color: var(--blue-50);
        border: 1px solid var(--blue);
    }

    &--success {
        background-color: var(--green-50);
        border: 1px solid var(--green);
    }

    &--error {
        background-color: var(--red-50);
        border: 1px solid var(--red);
    }

    p,
    a {
        font-size: $font-s;
        line-height: 1.6;
    }

    a {
        color: var(--color);

        &:hover {
            border-color: var(--color);
        }
    }
}
</style>
