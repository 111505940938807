// mouse.js
import { onBeforeMount } from 'vue';
import { getBridgableCollections } from './services/bridge';
import { useState, toasts } from './store';
import { Toast } from './types';

export function useInitState() {
    const store = useState();

    if (!store.bridgable) {
        onBeforeMount(async () => {
            store.bridgable = await getBridgableCollections();
        });
    }
}

export const useToasts = ({ type, message, timestamp = Date.now(), link }: Toast) => {
    toasts.value.push({
        type,
        message,
        timestamp,
        link,
    });
};
