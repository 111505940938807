<template>
    <div class="loader">
        <span></span>
        <span></span>
    </div>
</template>

<style lang="scss">
.loader {
    font-size: 2rem;
    display: block;
    position: relative;
    width: 4em;
    height: 4em;

    span {
        position: absolute;
        border: 0.2em solid var(--color);
        border-radius: 50%;
        box-sizing: content-box;
        top: 2.5%;
        left: -2.5%;
        width: 90%;
        height: 90%;
        animation: ripple 2.6s ease-out infinite;
    }

    span:nth-child(2) {
        animation-delay: -1.2s;
    }

    &.full {
        margin: 20vh auto;
    }

    &.center {
        margin: $spacing auto;
    }

    &.small {
        font-size: 1.8rem;
        width: 2em;
        height: 2em;

        span {
            top: -5%;
            left: -5%;
        }
    }

    &.tiny {
        font-size: 1.6rem;
        width: 1.5em;
        height: 1.5em;

        span {
            top: -10%;
            left: -10%;
        }
    }
}

@keyframes ripple {
    0% {
        transform: scale3d(0.1, 0.1, 1);
        opacity: 1;
    }

    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
}
</style>
